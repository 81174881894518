import { Route } from '@angular/router';
import { languageResolver } from 'tofe-language';
import { processResolver } from 'tofe-session';

import { customizationResolver } from './core/services/customization/customization.resolver';

/* istanbul ignore next */
export const appRoutes: Route[] = [
  {
    path: '',
    resolve: {
      customization$: customizationResolver,
      process$: processResolver,
      language: languageResolver,
    },
    loadComponent: () => import('./core/pages/main-page.component').then((mod) => mod.MainPageComponent),
  },
  {
    path: 'playground',
    loadComponent: () => import('./core/pages/playground-page.component').then((mod) => mod.PlaygroundPageComponent),
  },
  {
    path: 'page-not-found',
    loadComponent: () => import('./core/pages/not-found-page.component').then((mod) => mod.NotFoundPageComponent),
  },
  {
    path: '**',
    redirectTo: '/page-not-found',
  },
];
