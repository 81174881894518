import { isDevMode } from '@angular/core';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';

import { HttpLoaderService } from './http-loader.service';

export const provideTranslations = () => {
  return [
    provideTransloco({
      config: {
        availableLangs: [
          'it',
          'en',
          'de',
          'fr',
          'es',
          'pt',
          'nl',
          'pl',
          'ro',
          'ru',
          'tr',
          'ar',
          'zh',
          'ja',
          'ko',
          'cs',
          'sk',
          'zz',
        ],
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
        missingHandler: {
          useFallbackTranslation: false,
          allowEmpty: true,
          logMissingKey: false,
        },
      },
      loader: HttpLoaderService,
    }),
    provideTranslocoMessageformat(),
    provideTranslocoLocale(),
  ];
};
