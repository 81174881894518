/**
 * we've copied this file from the onboarding-pecq-fe project
 * @see https://gitlab.mgmt.infocert.it/plain/onboarding-pecq-fe/-/blob/main/src/app/core/services/transloco-http-loader.service.ts
 */
import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { TofeLanguageCustomization } from 'top-api-sdk-angular';
import { merge } from 'ts-deepmerge';

export const AllLanguagesList = ['it', 'en', 'de', 'es', 'fr', 'pl', 'cs', 'sk'];

const withFallback = <T>(observable$: Observable<T>, fallback: Observable<T>): Observable<T> =>
  observable$.pipe(
    catchError(() => {
      return fallback;
    }),
  );

const withMerge = <T>(observable$: Observable<T>, fallback: Observable<T>): Observable<T> =>
  observable$.pipe(
    switchMap((res) => {
      return fallback.pipe(map((res2) => merge(res2 as object, res as object) as T));
    }),
  );

@Injectable()
export class LanguageConfigurationService implements TranslocoLoader {
  // eslint-disable-next-line sonarjs/public-static-readonly
  public static moduleConfig = { isViewTokenEnabled: false };
  private customization?: TofeLanguageCustomization;

  constructor(
    private http: HttpClient,
    @Inject(APP_BASE_HREF) private _baseHref: string,
  ) {}

  setCustomization(customization: TofeLanguageCustomization) {
    this.customization = customization;
  }

  getTranslation(lang: string): Observable<Translation> {
    const settings = this.customization?.translations || [
      {
        baseUrl: `${this._baseHref}assets/i18n`,
        mode: ['fallback'],
      },
    ];
    return settings.reverse().reduce((acc, curr) => {
      if (!curr.baseUrl) return acc;
      const obs$ = this.get(curr.baseUrl, lang);
      switch (curr.mode) {
        case 'merge':
          return withMerge(obs$, acc);
        case 'merge-or-fallback':
          return withFallback(withMerge(obs$, acc), acc);
        case 'fallback':
        default:
          return withFallback(obs$, acc);
      }
    }, of({}));
  }

  private get(url: string, lang: string): Observable<Translation> {
    return this.http.get<Translation>(`${url}/${lang}.json`);
  }

  getAvailableLanguages(): string[] {
    const ret = this.customization?.availableLanguages || AllLanguagesList;
    if (LanguageConfigurationService.moduleConfig.isViewTokenEnabled) {
      return [...ret, 'zz'];
    } else {
      return ret;
    }
  }

  getDefaultLanguage(): string {
    return this.customization?.defaultLanguage || 'en';
  }
}
