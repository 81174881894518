import { ResolveFn } from '@angular/router';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { from, of, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { getLogger } from 'tofe-core';

const debug = getLogger('[Resolver][Language]').debug;

export const takeLangFromDevice = () =>
  from(Preferences.get({ key: 'tofe-language' })).pipe(
    switchMap(({ value }) =>
      value
        ? of(value).pipe(
            tap((y) => {
              debug(`get from storage`, y);
            }),
          )
        : from(Device.getLanguageCode()).pipe(
            map(({ value }) => (value || '').substring(0, 2)),
            tap((y) => {
              debug(`get from device`, y);
            }),
          ),
    ),
  );

export const languageResolver: ResolveFn<string> = (route) => {
  const lang = route.queryParamMap.get('lang') as string;
  debug('hit', lang);
  if (lang) {
    return of(lang);
  }
  return takeLangFromDevice();
};
