import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';

import { BrowserRefInterceptorProvider } from './interceptors/browser-ref.interceptor';
import { GlobalErrorHandlerProvider } from './interceptors/global-error-handler';
import { ProcessInResponseInterceptorProvider } from './interceptors/process-in-response.interceptor';
import { ProcessInResponseService } from './process-in-response.service';
import { TopSessionService } from './top-session.service';
import { TofeSessionModuleConfig } from './types';

export const TOFE_SESSION_CONFIG = new InjectionToken<TofeSessionModuleConfig>('TOFE_SESSION_CONFIG');

@NgModule({})
export class TofeSessionModule {
  static forRoot(_config: TofeSessionModuleConfig): ModuleWithProviders<TofeSessionModule> {
    return {
      ngModule: TofeSessionModule,
      providers: [
        { provide: TOFE_SESSION_CONFIG, useValue: _config },
        GlobalErrorHandlerProvider,
        BrowserRefInterceptorProvider,
        ProcessInResponseInterceptorProvider,
        ProcessInResponseService,
        TopSessionService,
      ],
    };
  }
}
