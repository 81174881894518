import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { getLogger } from 'tofe-core';
import { TofeCustomization } from 'top-api-sdk-angular';

import { CustomizationService } from './customization.service';

const logger = getLogger('[Resolver][Customization]');

export const customizationResolver: ResolveFn<TofeCustomization> = (route) => {
  const service = inject(CustomizationService);
  const customization = route.queryParamMap.get('customization') as string;
  logger.debug('hit', customization);
  return service.setCurrent(customization);
};
