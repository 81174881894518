import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { initLogger } from 'tofe-core';
import { LanguageService } from 'tofe-language';

@Component({
  imports: [RouterModule, CommonModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'tofe';

  constructor(languageService: LanguageService) {
    initLogger();
    languageService.init().subscribe();
  }
}
