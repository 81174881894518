import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { errorState, HttpRequestState, httpRequestStates, loadedState } from 'ngx-http-request-state';
import { defer, from, Observable, of, switchMap } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { getLogger } from 'tofe-core';
import { ProcessInfo, ProcessService, StartErrorResponse, StartResponse } from 'top-api-sdk-angular';

import { SessionInfoRequestState } from './top-session.service';
import { SessionInfo } from './types';
import { isMobile } from './utils';

const log = getLogger('[Resolver][Process]').log;

const DEFAULT_ERROR_MESSAGE = 'TOFE.PROCESS_RESOLVER.GENERIC_ERROR';

export class StartError extends Error {}

/**
 * t(TOFE.PROCESS_RESOLVER.GENERIC_ERROR.TITLE)
 * t(TOFE.PROCESS_RESOLVER.GENERIC_ERROR.DESCRIPTION)
 * t(TOFE.PROCESS_RESOLVER.AUTH_FAILED.TITLE)
 * t(TOFE.PROCESS_RESOLVER.AUTH_FAILED.DESCRIPTION)
 * t(TOFE.PROCESS_RESOLVER.USED_TOKEN.TITLE)
 * t(TOFE.PROCESS_RESOLVER.USED_TOKEN.DESCRIPTION)
 * t(TOFE.PROCESS_RESOLVER.EXPIRED_TOKEN.TITLE)
 * t(TOFE.PROCESS_RESOLVER.EXPIRED_TOKEN.DESCRIPTION)
 */
const getStartErrorMessage = (payload: StartErrorResponse) => {
  switch (payload.errorKey) {
    case 'SK099':
      return 'TOFE.PROCESS_RESOLVER.AUTH_FAILED';
    case 'COP-0001':
      return 'TOFE.PROCESS_RESOLVER.USED_TOKEN';
    case 'INVALID_TOKEN':
      if (payload.errorMessage.includes('expired')) {
        return 'TOFE.PROCESS_RESOLVER.EXPIRED_TOKEN';
      } else {
        return 'TOFE.PROCESS_RESOLVER.INVALID_TOKEN';
      }
    default:
      return DEFAULT_ERROR_MESSAGE;
  }
};

const interceptStartErrors = (x: HttpRequestState<StartResponse>) => {
  if (x.error instanceof HttpErrorResponse) {
    const originalError = x.error;
    const payload = originalError.error as StartErrorResponse;
    const error = new StartError(getStartErrorMessage(payload));
    return { ...x, error, originalError };
  }
  return x;
};

export const processResolver: ResolveFn<Observable<Observable<SessionInfoRequestState>>> = (route) => {
  const api = inject(ProcessService);
  const id = route.queryParamMap.get('id') as string;
  log('hit', id);
  return defer(() => of(getProcessObservable(api, id).pipe(map(interceptRouteErrors(route)))));
};

const mergeProcessInfoInSession =
  (id: string, startResult: HttpRequestState<StartResponse>) => (processResult: HttpRequestState<ProcessInfo>) => {
    if (processResult.value) {
      return loadedState<SessionInfo>({
        id,
        startData: startResult.value,
        processData: processResult.value,
      });
    } else {
      return processResult as HttpRequestState<SessionInfo>;
    }
  };

export const interceptProcessErrors = async (
  res: HttpRequestState<SessionInfo>,
): Promise<HttpRequestState<SessionInfo>> => {
  const isMobileOnly = res.value?.processData?.tofeConfiguration?.mobileOnly;
  const isMobileOnlyError = isMobileOnly && !(await isMobile());
  if (isMobileOnlyError) {
    return { isLoading: false, error: new StartError('mobile_only') };
  }
  return res;
};

const getProcessObservable = (api: ProcessService, id?: string): Observable<SessionInfoRequestState> => {
  if (!id) {
    return of(errorState<SessionInfo>(new StartError(DEFAULT_ERROR_MESSAGE)));
  }
  return api.startProcess(id, { id }).pipe(
    httpRequestStates(),
    map(interceptStartErrors),
    switchMap((startResult) => {
      if (startResult.isLoading || startResult.error) {
        return of(startResult as HttpRequestState<SessionInfo>);
      }
      const sessionKey = startResult?.value?.sessionKey;
      if (!sessionKey) {
        // it is still possible that start returns a valid response but without a sessionKey
        return of({
          ...errorState<SessionInfo>(new StartError(DEFAULT_ERROR_MESSAGE)),
          originalValue: startResult.value,
        });
      }
      api.configuration.credentials['CopApiKey'] = 'COP ' + sessionKey;
      return api.getProcess(id).pipe(httpRequestStates(), map(mergeProcessInfoInSession(id, startResult)));
    }),
    tap((res) => {
      log('', res);
    }),
    switchMap((res) => from(interceptProcessErrors(res))),
    shareReplay(1),
  );
};

export const interceptRouteErrors = (route: ActivatedRouteSnapshot) => (res: SessionInfoRequestState) => {
  if (res.error instanceof StartError) {
    const spidError = route.queryParamMap.get('spidError') as string;
    if (spidError) {
      return {
        ...res,
        error: new StartError(getSpidErrorMessage(spidError)),
      } as SessionInfoRequestState;
    }
  }
  return res;
};

/**
 * t(TOFE.PROCESS_RESOLVER.SPID_ERROR.TITLE)
 * t(TOFE.PROCESS_RESOLVER.SPID_ERROR.DESCRIPTION)
 * t(TOFE.PROCESS_RESOLVER.SPID_ERROR_DATA_MISMATCH.TITLE)
 * t(TOFE.PROCESS_RESOLVER.SPID_ERROR_DATA_MISMATCH.DESCRIPTION)
 */
export const getSpidErrorMessage = (spidError: string) => {
  if (spidError === '514') {
    return 'TOFE.PROCESS_RESOLVER.SPID_ERROR_DATA_MISMATCH';
  }
  return 'TOFE.PROCESS_RESOLVER.SPID_ERROR';
};
