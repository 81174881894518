import { inject } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

let logger: NGXLogger;

export const initLogger = () => {
  logger = inject(NGXLogger);
};

export const log = (message: string, data: unknown) => {
  if (logger) logger.log(message, data);
};

export const error = (message: string, data: unknown) => {
  if (logger) logger.error(message, data);
};

export const debug = (message: string, data: unknown) => {
  if (logger) logger.debug(message, data);
};

export const getLogger = (prefix: string, enabled = true) => ({
  log: (message: string, data?: unknown) => {
    if (enabled) {
      log(`${prefix}: ${message}`, data);
    }
  },
  debug: (message: string, data?: unknown) => {
    if (enabled) {
      debug(`${prefix}: ${message}`, data);
    }
  },
  error: (message: string, data?: unknown) => {
    error(`${prefix}: ${message}`, data);
  },
});
